import React from "react";
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';

import Notification from './Notification';

function RegisterTeam() {
  const [title, setTitle] = useState("");
  const [message,setMessage] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [linkTo, setLinkTo] = useState(""); 

  const url = "https://ssbc.club/db/all_posts.php";
  const apiUrl ="https://ssbc.club/emailApi/email.php";

  function Submit(e) {
    document.getElementById("register").disabled=true;
    const formElm = document.querySelector('form');
    e.preventDefault();
    
    const fData = new FormData(formElm);
    fData.append('request', "teamreg");

    axios.post(url, fData)
      .then(response => {
        if(response.data === "Successful"){
          sendEmail(fData);
          document.getElementById("teamForm").reset();
        }else {
          setTitle("Warning");
          setMessage(response.data);
          setModalShow(true);
        }
        document.getElementById("register").disabled=false;
        
      })
      .catch(error => {
        alert(error)
        setTitle("Error");
        setMessage(error);
        setModalShow(true);
        document.getElementById("register").disabled=false;
      });
  }

  const sendEmail = (fData) => {
    fData.append('request', 'teamreg');
    axios.post(apiUrl, fData)
      .then(response => {
        setLinkTo("/Tournament2024")
        setTitle("Registration Completed");
        setMessage(response.data);
        setModalShow(true);
      })
      .catch(error => {
        setTitle("Failed to Register");
        setMessage(error); 
        setModalShow(true);
        
      })
  }

  return (
    <div className="container">
      <div className="mt-2 p-4 p-md-5 mb-4 rounded text-bg-dark">
        <div className="p-2 border border-white rounded text-center">
          <h5>Team Entry Form</h5>
        </div>
        <form className="mt-2 mb-3 needs-validation" id="teamForm" onSubmit={(e) => Submit(e)}>                   
          <div class="mt-2 form-group col-md-8">
            <label for="player">Player's Name: </label>
            <input
              type="text"
              className="form-control"
              id="player"
              name="player"
              required
            />
          </div>
          <div class="mt-2 form-group col-md-8">
            <label for="partner">Partner's Name: </label>
            <input
              type="text"
              class="form-control"
              id="partner"
              name="partner"
              required
            />
          </div>
          <div class="mt-3 form-group col-md-3">
            <select
              class="form-select"
              aria-label="Default select example"
              id="level"
              name="level"
              required >
              <option selected>Select Group </option>
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
              <option value="Girls">Girls</option>
              <option value="Mixed">Mixed</option>
            </select>
          </div>
          <div className="mt-2 form-group col-md-8">
            <label for="email">Email address</label>
            <input
              type="email"
              class="form-control"
              id="email"
              name="email"
              required
            />
          </div>
          <div class="mt-2 form-group col-md-4">
            <label for="phone">Phone Number: </label>
            <input
              type="number"
              class="form-control"
              id="phone"
              name="phone"
              pattern="[0-9]*"        
              required
            />
          </div>
          <div class="mt-2 form-group col-md-4">
            <label for="City">City: </label>
            <input
              type="text"
              class="form-control"
              id="city"
              name="city"          
              required
            />
          </div>

          <div class="mt-3 form-check">
            <input
              type="checkbox"
              name="agreement"
              class="form-check-input"
              required
            />
            <label for="agreement">
              I agree to the terms and conditions
            </label>
          </div>

          <div className="form-group mt-3">
            <button
              type="submit"
              id="register"
              class="btn btn-primary"
              >Submit</button>
          </div>
        </form>
        <div className="p-2 border border-white rounded">
          <p className="fw-bold">Entry Fees:</p>
          Men's Doubles: 500kr<br/>
          Miexed Double: 500kr<br/>
          Women's Double: 300<br/>
        </div>
      </div>
      <Notification
        show={modalShow}
        onHide={() => setModalShow(false)}
        title = {title}
        message = {message}
        linkto = {linkTo}
      />
    </div>
  )
}
export default RegisterTeam;